import React from "react";

const EventComparisonHeader = () => {
  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className="flex items-center mb-4">
        <h1 className="text-4xl md:text-6xl font-semibold text-black">Event</h1>
        <img
          src="static/images/hero_artist.png"
          className="h-8 md:h-12 w-auto mx-4"
        />
        <h1 className="text-4xl md:text-6xl font-semibold text-black">ticket</h1>
      </div>
      <div className="flex items-start">
        <h1 className="text-4xl md:text-6xl font-semibold text-black">Comparison</h1>
        <img className="star" src="static/images/hero_star.svg" />
      </div>
    </div>
  );
};

export default EventComparisonHeader;
