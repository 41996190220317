/**
 * Module for hold all app enums values.
 * @module enums
 */

/**
 * Enum booking.com environment.
 * @readonly
 * @enum {string}
 */
enum ENV {
  PROD = "prod",
  DQS = "dqs",
  DEV = "dev",
}

enum PAGES {
  HOME,
  PERFORMER,
  EVENT,
  SPORT,
  CONTACT,
  ABOUT,
  BLOGS,
  BLOG,
  SEATMAP,
}

enum API_NAMES {
  APP_PRELOAD = "APP_PRELOAD",
  SPORT = "sport",
  EVENT = "event",
  PERFORMER = "performer",
  BLOGS = "blogs",
  BLOG = "blog",
  SEARCH = "search",
  TOP_EVENTS = "top",
}

enum API_RESPONSE_ERROR_TYPE {
  NOT_FOUND = "NOT_FOUND",
  SERVER_ERROR = "SERVER_ERROR",
  TIME_OUT = "TIME_OUT",
}

export { ENV, API_NAMES, API_RESPONSE_ERROR_TYPE, PAGES };
