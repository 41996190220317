import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import IMainContextData from "../models/MainContextDataModel";
const defaultState: IMainContextData = {
  initialized: false,
};

// Exports
// export const MainContext = createContext({});
// export const DispatchMainContext = createContext({} as Dispatch<SetStateAction<IMainContextData>>);

type MainContextProps = {
  children: React.ReactNode;
};

const MainContext = createContext({
  mainContextData: defaultState,
  setMainContextData: {} as Dispatch<SetStateAction<IMainContextData>>,
});

export const MainContextProvider: React.FunctionComponent<MainContextProps> = ({
  children,
}) => {
  const [mainContextData, setMainContextData] = useState(defaultState);
  return (
    <MainContext.Provider value={{ mainContextData, setMainContextData }}>
      {children}
    </MainContext.Provider>
  );
};

export const MainContextConsumer: React.FunctionComponent<{
  children: any;
}> = ({ children }) => {
  return (
    <MainContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            "TemplateConsumer must be used within TemplateProvider"
          );
        }
        return children(context);
      }}
    </MainContext.Consumer>
  );
};

// useTemplate Hook
export const useMainContext = () => {
  const context = useContext(MainContext);
  if (context === undefined)
    throw new Error("useTemplate must be used within TemplateProvider");
  return context;
};
