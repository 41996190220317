import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const PriceComparisonItem: React.FunctionComponent<{
  imgSrc: string;
  price: number;
  ticketUrl: string;
}> = ({ imgSrc, price, ticketUrl }) => {
  const formatPrice = (price: number) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(price);
  };
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid gray",
        borderRadius: "8px",
        padding: "8px",
        minWidth: "40%",
        maxWidth: "calc(50% - 4px)",
        flex: 1,
        textDecoration: "none",
        color: "black",
      }}
      component="a"
      href={ticketUrl}
      target="_blank"
    >
      <img src={imgSrc} style={{ width: "60px" }} />
      <span style={{ whiteSpace: "nowrap" }}>{formatPrice(price)} Each</span>
    </Box>
  );
};

export default PriceComparisonItem;
