import { FC, ReactNode } from "react";

interface ContactUsPageProp {
  children?: ReactNode;
}

const ContactUsInfo = [
  {
    title: "Call Us",
    description: ["+1 0123 456 789", "+1 0123 456 789"],
    logo: "/static/images/contact/contact-phone.svg",
  },
  {
    title: "Message Us",
    description: ["contact@email.com", "contact@email.com"],
    logo: "/static/images/contact/contact-email.svg",
  },
  {
    title: "Opening Hours",
    description: ["Monday-Friday: 8:00AM to 6:00PM", "Weekend: Closed"],
    logo: "/static/images/contact/contact-hours.svg",
  },
];

const ContactUsPage: FC<ContactUsPageProp> = () => {
  return (
    <div className="flex flex-col items-center max-w-6xl justify-center m-auto">
      <div className="flex flex-col items-center justify-center pt-6 md:pt-10 lg:pt-16">
        <img
          src="/static/images/contact/contact-logo.svg"
          alt=""
          className="h-20 w-auto"
        ></img>
        <h1 className="text-3xl md:text-5xl font-semibold text-black mt-4">
          Contact Us
        </h1>
        <p className="text-lg text-black font-light mt-2 text-center block max-w-2xl p-2">
          Have a question but aren’t sure who to contact? Fill in the form to
          get in touch and a member of our team will reach out to you at the earliest.
        </p>
        <img
          src="/static/images/contact/contact-stadium.svg"
          className="h-80 w-auto my-10 md:block hidden"
          alt="contact us map"
        ></img>
      </div>
      <div className="w-full px-4 md:px-32 space-x-16 mt-10 md:mb-20 grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-12">
        {ContactUsInfo.map((info) => (
          <div
            key={info.title}
            className="flex flex-col w-full !m-0 items-start p-4 bg-primary-btn rounded-xl"
          >
            <img src={info.logo} alt="" className="h-12 w-auto"></img>
            <h2 className="text-2xl font-normal text-white mt-6">
              {info.title}
            </h2>
            <div className="flex flex-col items-start mt-2">
              {info.description.map((desc) => (
                <p key={desc} className="text-md text-white font-light">
                  {desc}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="w-full md:hidden px-4 flex items-center justify-center">
      <img
          src="/static/images/contact/contact-stadium.svg"
          className="h-80 w-auto my-10 block"
          alt="contact us map"
        ></img>
      </div>

    </div>
  );
};
export default ContactUsPage;
