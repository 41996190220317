import { useRoutes } from 'react-router-dom';
import router from './router';
import { ToastProvider } from 'react-toast-notifications';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { MainContextProvider } from "./contexts/MainContext";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DialogContextProvider } from './contexts/DialogContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReactGA from 'react-ga4';
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

// ----------------------------------------------------------------------
const TIME_ZONE = "America/Los_Angeles";
dayjs.extend(timezone);
dayjs.tz.setDefault(TIME_ZONE);
import 'dayjs/locale/en';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function App() {
  const content = useRoutes(router);
  ReactGA.initialize('G-85SJ8JSL64');
  const firebaseApp = initializeApp({
    apiKey: "AIzaSyCQp5jQQb50N6DykfoK6VWyzZ1XoeB6T7Q",
    authDomain: "tixplorer-bfb02.firebaseapp.com",
    projectId: "tixplorer-bfb02",
    storageBucket: "tixplorer-bfb02.appspot.com",
    messagingSenderId: "119841097413",
    appId: "1:119841097413:web:306deadbf693ed992c92ac",
    measurementId: "G-849WBJN5XR"
  });
  getAnalytics(firebaseApp);
  if(location.hostname === "localhost") {
    connectFirestoreEmulator(getFirestore(), "127.0.0.1", 8080);
    /* fetch("http://127.0.0.1:9099")
    .then(() => {
      connectAuthEmulator(getAuth(), "http://127.0.0.1:9099");
    }).catch(console.error) */
      
  }

  const queryClient = new QueryClient()
  
  return (
    <QueryClientProvider client={queryClient}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider>
        <ToastProvider>
          <CssBaseline />
          <MainContextProvider>
            <DialogContextProvider>
              {content}
            </DialogContextProvider>
          </MainContextProvider>
        </ToastProvider>
      </ThemeProvider>
    </LocalizationProvider>
    </QueryClientProvider>
  );
}
export default App;
