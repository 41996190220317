import { useContext, useEffect, useState } from 'react';


import {
  ListSubheader,
  alpha,
  Box,
  List,
  ListItem,
  Typography
} from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { SidebarContext } from '../../../../contexts/SidebarContext';
import MenuItem from './MenuItem';
import { styled } from '@mui/material/styles';
import { DialogContext } from '../../../../contexts/DialogContext';
import { useCookies } from 'react-cookie';
import axios from '../../../../api/axios';
const MenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: ${theme.spacing(1)};
    }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(14)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      .MuiListItem-root {
        padding: 1px 0;
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};
          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }       
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const dialogs = useContext(DialogContext);
  const [cookies, setCookie, removeCookie] = useCookies();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    // checkAuth();
    // getBalance();
  }, [])

  async function checkAuth() {
    try {
      const token = localStorage.getItem("token") || cookies["token"] || "";
      const response = await axios.get("/api/users/auth", {
        headers: {
          token,
        }
      });
      if(response.status === 200) {
        setIsLoggedIn(true);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  async function getBalance() {
    try {
      const response = await axios.get("/api/users/balance");
      if(response.status === 200) {
        setBalance(response.data.balance);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  function handleViewOrders() {
    dialogs.openDialog("cashbackViewOrders");
  }

  function handleAddOrder() {
    dialogs.openDialog("cashbackAddOrder");
  }

  function handleWithdraw() {
    dialogs.openDialog("cashbackWithdraw");
  }

  function handleSettings() {
    dialogs.openDialog("settings");
  }

  async function handleSignOut() {
    await signOut(getAuth());
    localStorage.removeItem("token");
    removeCookie("token");
    setIsLoggedIn(false);
  }

  return (
    <>
      <MenuWrapper>
        <List
          component="div"
        >
          <SubMenuWrapper>
            <List component="div">
              {
                /* isLoggedIn?
                <>
                  <ListItem style={{ marginLeft: '12%', marginBottom: 10, }}>
                    <Typography variant='h5' fontSize={18}>Balance: {balance}</Typography>
                  </ListItem>
                  <ListItem onClick={() => {closeSidebar(); handleViewOrders();}}>
                    <MenuItem text='View Orders' href="#" />
                  </ListItem>
                  <ListItem onClick={() => {closeSidebar(); handleAddOrder();}}>
                    <MenuItem text='Add Order' href="#" />
                  </ListItem>
                  <ListItem onClick={() => {closeSidebar(); handleWithdraw();}}>
                    <MenuItem text='Withdraw' href="#" />
                  </ListItem>
                  <ListItem onClick={() => {closeSidebar(); handleSettings();}}>
                    <MenuItem text='Settings' href="#" />
                  </ListItem>
                  <ListItem onClick={() => {closeSidebar(); handleSignOut();}}>
                    <MenuItem text='Sign Out' href="#" />
                  </ListItem>
                </>
                :
                <>
                  <ListItem component="div" onClick={() => {closeSidebar(); dialogs.openDialog("login");}}>
                    <MenuItem text='LOG IN' href='#' />
                  </ListItem>
                  <ListItem component="div" onClick={() => {closeSidebar(); dialogs.openDialog("register");}}>
                    <MenuItem text='REGISTER' href='#' />
                  </ListItem>
                </> */
              }
              <hr style={{ width: '80%' }} />
              <ListItem component="div" onClick={closeSidebar}>
                <MenuItem text='HOME' href='/' />
              </ListItem>
              <ListItem component="div" onClick={closeSidebar}>
                <MenuItem text='ABOUT US' href='/about' />
              </ListItem>
              <ListItem component="div" onClick={closeSidebar}>
                <MenuItem text='BLOG' href='/blogs' />
              </ListItem>
              <ListItem component="div" onClick={closeSidebar}>
                <MenuItem text='CONTACT US' href='/contact' />
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper >
    </>
  );
}

export default SidebarMenu;
