import { ComparisonData, QTY_FILTER_VALUES } from "../types/ticketTypes";

const filterComparisonBySection = (
  comparisonData: ComparisonData,
  selectedSections: string[]
): ComparisonData => {
  const newComparisonData: ComparisonData = {};

  Object.keys(comparisonData).forEach((key) => {
    if (selectedSections.includes(comparisonData[key].section)) {
      newComparisonData[key] = comparisonData[key];
    }
  });

  return newComparisonData;
};

export const filterTickets = (
  tickets: ComparisonData,
  qty?: number,
  sections?: string[]
) => {
  const sectionFiltered =
    sections && sections.length > 0
      ? filterComparisonBySection(tickets, sections)
      : tickets;

  if (!qty) {
    return sectionFiltered;
  }

  const newComparisonData: ComparisonData = {};

  Object.keys(sectionFiltered).forEach((key) => {
    const ticket = sectionFiltered[key];
    if (ticket.quantity.includes("-")) {
      const [minQty, maxQty] = ticket.quantity
        .split("-")
        .map((qty) => parseInt(qty));
      if (qty >= minQty && qty <= maxQty) {
        newComparisonData[key] = ticket;
      }
    } else {
      const intQty = parseInt(ticket.quantity);
      if (qty === QTY_FILTER_VALUES[QTY_FILTER_VALUES.length - 1]) {
        if (intQty >= qty) {
          newComparisonData[key] = ticket;
        }
      } else if (intQty === qty) {
        newComparisonData[key] = ticket;
      }
    }
  });

  return newComparisonData;
};
