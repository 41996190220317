import React, { Suspense, lazy } from "react";
import { RouteObject } from "react-router";

import SuspenseLoader from "./components/SuspenseLoader";
import AboutUsPage from "./content/pages/about";
import BlogsPage from "./content/pages/blogs";
import BlogPage from "./content/pages/blogs/blog";
import ContactUsPage from "./content/pages/contact";
import Event from "./content/pages/event";
import Performer from "./content/pages/performer";
import SportPage from "./content/pages/sport";
import Home from "./content/pages/home";
import { PAGES } from "./Enums";
import MainLayout from "./layouts/MainLayout";
import ReactGA from "react-ga4";
import SeatMapPage from "./content/pages/SeatMap";

const Loader = (Component: React.FunctionComponent) => {
  const comp = (props: any) => (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
  return comp;
};

const Status404 = Loader(
  lazy(() => import("./content/pages/Status/Status404"))
);

const PageWrapper = ({ page }: { page: PAGES }) => {
  const pathname = window.location.pathname;
  ReactGA.send({ hitType: "pageview", page: pathname });
  switch (page) {
    case PAGES.HOME:
      return <Home />;
    case PAGES.PERFORMER:
      return <Performer />;
    case PAGES.EVENT:
      return <Event />;
    case PAGES.SPORT:
      return <SportPage />;
    case PAGES.ABOUT:
      return <AboutUsPage />;
    case PAGES.CONTACT:
      return <ContactUsPage />;
    case PAGES.BLOGS:
      return <BlogsPage />;
    case PAGES.BLOG:
      return <BlogPage />;
    case PAGES.SEATMAP:
      return <SeatMapPage />;
    default:
      return <Status404 />;
  }
};

const routes: RouteObject[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        index: true,
        element: <PageWrapper page={PAGES.HOME} />,
      },
      {
        path: "/sports/:sportCode",
        element: <PageWrapper page={PAGES.SPORT} />,
      },
      {
        path: "/events/:eventId",
        element: <PageWrapper page={PAGES.EVENT} />,
      },
      /* Intentionally using the same page */
      {
        path: "/performers/:performerId/events/:eventId",
        element: <PageWrapper page={PAGES.EVENT} />,
      },
      {
        path: "/performers/:performerId",
        element: <PageWrapper page={PAGES.PERFORMER} />,
      },
      {
        path: "/about",
        element: <PageWrapper page={PAGES.ABOUT} />,
      },
      {
        path: "/contact",
        element: <PageWrapper page={PAGES.CONTACT} />,
      },
      {
        path: "/blogs",
        element: <PageWrapper page={PAGES.BLOGS} />,
      },
      {
        path: "/blogs/:blogCode",
        element: <PageWrapper page={PAGES.BLOG} />,
      },
      {
        path: "/seatmap",
        element: <PageWrapper page={PAGES.SEATMAP} />,
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
];

export default routes;
