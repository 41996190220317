import { FC, ReactNode } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";

interface BgHeaderProps {
  image?: string;
  children?: ReactNode;
  title?: string;
  titleElement?: ReactNode;
  subTitle?: string;
  contentStyle?: React.CSSProperties;
}

function BgHeader({
  children,
  title,
  titleElement,
  subTitle,
  image,
  contentStyle,
}: BgHeaderProps) {
  const theme = useTheme() as Theme;
  return (
    <div
      style={{
        backgroundImage: `url("${image ? image : "/static/images/bg.png"}")`,
        backgroundColor: "#111",
        opacity: 0.8,
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <div
        style={{
          position: "relative",
          //   padding: theme.spacing(2),
          //   background: (children || title) && "radial-gradient(circle, rgb(26 21 0) 0%, rgb(1 1 0 / 87%) 24%, rgb(1 1 0 / 77%) 44%, rgb(0 0 0 / 47%) 76%, rgb(0 0 0 / 17%) 96%)"
        }}
      >
        <img
          id="background-hero-image"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
          }}
          src={image}
        />
        <Box
          sx={{
            height: { xs: "300px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: theme.page.containerWidth,
            margin: "auto",
            position: "relative",
            ...contentStyle,
          }}
        >
          {title !== undefined && (
            <Typography
              variant="h1"
              color={theme.colors.alpha.black["100"]}
              sx={{ fontSize: { md: 80, xs: 40 }, fontWeight: 500 }}
              textAlign={"center"}
            >
              {title}
            </Typography>
          )}
          {titleElement !== undefined && <>{titleElement}</>}
          {subTitle !== undefined && (
            <Typography
              variant="h2"
              color={theme.colors.alpha.white["100"]}
              sx={{ fontSize: { md: 40, xs: 30 }, fontStyle: "italic" }}
              textAlign={"center"}
            >
              {subTitle}
            </Typography>
          )}
          {children}
        </Box>
      </div>
    </div>
  );
}
export default BgHeader;
