import React, { useContext, useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogActions, Button, Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DialogContext } from '../../../contexts/DialogContext';
import axios from '../../../api/axios';
import { CashbackMethod } from '../../../types';

function withdrawDialog() {

  const dialogContext = useContext(DialogContext);

  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [methodId, setMethodId] = useState("");
  const [methods, setMethods] = useState<CashbackMethod[]>([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if(!dialogContext.cashbackWithdraw) return;
    getMethods();
  }, [dialogContext.cashbackWithdraw])

  async function getMethods() {
    try {
      const response = await axios.get("/api/cashback/methods");
      setMethods(response.data.methods);
    } catch (err) {
      console.warn(err);
    }
  }
  

  async function handleSubmit() {
    if(withdrawAmount < 500) {
      setError("Minimum withdraw amount is 500 TixPoints");
      return;
    }
    try {
      await axios.post("/api/cashback/redeem", {
        amount: withdrawAmount,
        methodId,
      });
      dialogContext.setMessage("Withdraw request submitted! It can takes up to 5 working days to process the request");
      closeDialog();
    } catch (err: any) {
      console.error(err)
      setError(err.response.data.error);
    }
  }
  
  function closeDialog() {
    dialogContext.closeDialog();
    setTimeout(() => {
      setWithdrawAmount(0);
      setError("");
    }, 100);
  }

  return (
    <Dialog open={dialogContext.cashbackWithdraw}>
      <DialogContent>
        <Grid container direction='column' spacing={'4px'}>
          <Grid item>
            <Typography variant='h3'>Redeem TixPoints</Typography>
          </Grid>
          <Grid item>
            <Grid container direction='column' spacing={'8px'}>
              {
                error && (
                  <Grid item>
                    <Typography variant='body1' color='error'>{error}</Typography>
                  </Grid>
                )
              }
              <Grid item>
                <Typography variant='body2'>Minimum redeemed amount is 500 TixPoints</Typography>
              </Grid>
              <Grid item>
                <Typography variant='caption'>100 TixPoints = 1.00 USD</Typography>
              </Grid>
              <Grid item>
                <Grid container spacing='4px'>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputLabel id='withdraw-method-label'>Withdraw Method</InputLabel>
                      <Select label="Withdraw Method" labelId='withdraw-method-label' fullWidth value={methodId} onChange={(e) => setMethodId(e.target.value as string)}>
                        {
                          methods.map((method) => (
                            <MenuItem key={method.id} value={method.id}>({method.provider}) {method.userIdentifier}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label='Redeemed Amount'
                      variant='outlined'
                      fullWidth
                      value={withdrawAmount}
                      onChange={(e) => setWithdrawAmount(parseInt(e.target.value))}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={closeDialog}>Cancel</Button>
        <Button variant='contained' onClick={handleSubmit}>Withdraw</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withdrawDialog