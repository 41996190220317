import React, {
  useState,
  useCallback,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { callAPI } from "../api";
import { API_NAMES } from "../Enums";
import { AxiosResponse } from "axios";

export type VisualComponentProps = {
  resultData: any;
  requestData?: Record<string, any>;
  isLoading: boolean;
  error: any;
  extra?: any;
};

type APIFetchContainerProps = {
  VisualComponent: React.FunctionComponent<VisualComponentProps>;
  requestData?: Record<string, any>;
  apiName: string;
  needDataFetch: boolean;
  extra?: any;
};

type APIFetchContainerState = {
  requestData?: Record<string, any>;
  apiName: API_NAMES;
  isLoading: boolean;
  isLoaded: boolean;
  error: any;
  needDataFetch: boolean;
  resultData: any;
};

const APIFetchContainer = forwardRef(
  (
    {
      VisualComponent,
      requestData = {},
      apiName,
      needDataFetch,
      extra,
    }: APIFetchContainerProps,
    ref
  ) => {
    const [data, setData]: [data: APIFetchContainerState, setData: any] =
      useState({
        apiName: apiName,
        requestData: requestData,
        isLoading: false,
        isLoaded: false,
        error: false,
        needDataFetch: false,
      } as APIFetchContainerState);
    useEffect(() => {
      setData((prev: APIFetchContainerState): APIFetchContainerState => {
        return {
          ...prev,
          resultData: null,
          isLoading: false,
          isLoaded: false,
          error: false,
          requestData: requestData,
          needDataFetch: true,
        };
      });
    }, [needDataFetch]);

    useImperativeHandle(ref, () => ({
      refresh() {
        setData((prev: APIFetchContainerState): APIFetchContainerState => {
          return {
            ...prev,
            resultData: null,
            isLoading: false,
            isLoaded: false,
            error: false,
            needDataFetch: true,
          };
        });
      },
      onRequestDataChange(params: Record<string, any>) {
        if (params === null) {
          setData((prev: APIFetchContainerState): APIFetchContainerState => {
            return {
              ...prev,
              resultData: null,
              isLoading: false,
              isLoaded: false,
              error: false,
              needDataFetch: false,
            };
          });
        } else {
          setData((prev: APIFetchContainerProps): APIFetchContainerProps => {
            return {
              ...prev,
              requestData: params,
              needDataFetch: true,
            };
          });
        }
      },
    }));
    // Do search query to fetch new search result
    const fetchData = useCallback(() => {
      setData({
        ...data,
        resultData: null,
        isLoading: true,
        needDataFetch: false,
      });
      callAPI(data.apiName, data.requestData || {})
        .then((response: AxiosResponse) => {
          setData((prev: APIFetchContainerState) => {
            return {
              ...prev,
              resultData: response.data,
              needDataFetch: false,
              isLoading: false,
              isLoaded: true,
            };
          });
        })
        .catch(function (error) {
          if (error.response && error.response.status === 404) {
            setData({
              ...data,
              resultData: null,
              needDataFetch: false,
              isLoaded: false,
              isLoading: false,
              error: false,
            });
          } else {
            setData({
              ...data,
              resultData: null,
              needDataFetch: false,
              isLoaded: false,
              isLoading: false,
              error: true,
            });
          }
        });
    }, [data]);

    useEffect(() => {
      if (data.needDataFetch === true) fetchData();
    }, [data.needDataFetch, fetchData]);
    return (
      <VisualComponent
        resultData={data.resultData}
        requestData={data.requestData}
        isLoading={data.isLoading}
        error={data.error}
        extra={extra}
      ></VisualComponent>
    );
  }
);
APIFetchContainer.displayName = "APIFetchContainer";

export default APIFetchContainer;
