import { FC, useContext } from "react";
import { Alert } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { DialogContext } from "../../contexts/DialogContext";

const PageContentWrapper: FC<{
  children: React.ReactNode;
  style?: Record<string, string>;
}> = ({ children, style }) => {
  const theme = useTheme() as Theme;
  const dialogContext = useContext(DialogContext);

  return (
    <div
      style={{
        maxWidth: theme.page.containerWidth,
        margin: "auto",
        padding: theme.spacing(1),
        ...style,
      }}
    >
      {dialogContext.error && (
        <Alert severity="error" sx={{ marginBottom: theme.spacing(2) }}>
          {dialogContext.error}
        </Alert>
      )}
      {dialogContext.message && (
        <Alert severity="success" sx={{ marginBottom: theme.spacing(2) }}>
          {dialogContext.message}
        </Alert>
      )}
      {children}
    </div>
  );
};
export default PageContentWrapper;
