import React, { useContext, useState } from 'react'
import { Button, Dialog, DialogContent, Grid, TextField, Typography } from '@mui/material'
import { DialogContext } from '../../../contexts/DialogContext';
import axios from '../../../api/axios';

function ForgotPasswordDialog() {

  const dialogContext = useContext(DialogContext);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  async function handleSubmit() {
    try {
      await axios.post("/api/users/forgot-password", { email });
      dialogContext.setMessage("Password reset email sent! Please check your inbox.");
      closeDialog();
    } catch (err: any) {
      console.error(err)
      setError(err.response.data.error);
    }
  }

  function closeDialog() {
    dialogContext.closeDialog();
    setTimeout(() => {
      setEmail("");
      setError("");
    }, 100);
  }

  return (
    <Dialog open={dialogContext.forgotPassword} onClose={closeDialog}>
      <DialogContent style={{ minWidth: 400 }}>
        <Grid direction='column'>
          <Typography variant='h4'>Reset Password</Typography>
          {
            error && 
            <Grid item>
              <Typography variant='body1' color='error' textAlign='center' style={{ marginTop: 10 }}>{error}</Typography>
            </Grid>
          }
          <Grid item>
            <TextField
              label='Email'
              variant='outlined'
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ margin: '20px 0px', }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button variant='contained' fullWidth onClick={handleSubmit}>Reset Password</Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ForgotPasswordDialog