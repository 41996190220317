import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

// ----------------------------------------------------------------------
const TIME_ZONE = "America/Los_Angeles";
dayjs.extend(timezone);
dayjs.extend(utc);
// dayjs.tz.setDefault(TIME_ZONE);
export function formatToLocal(dateString: string, format?: string) {
  return (
    dayjs(dateString.slice(0, 19))
      // .tz(TIME_ZONE)
      .format(format ?? "LLLL")
  );
}

export { dayjs, TIME_ZONE };
