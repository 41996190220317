import { FC, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import Slider, { Settings as SliderSettings } from "react-slick";
import SearchBox from "./SearchBox";

import "../../../index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styled from "styled-components";

import EventComparisonHeader from "../../../components/EventComparisonHeader";

const HighlightedText = styled.span`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    inset: 10px 0px -2px;
    background: #de6b2d;
    opacity: 0.23;
  }
`;

const GridIconWrapper = styled.div`
  width: 26px;
  height: 26px;
  background: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CategoryItem = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  padding: 5px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
  }
`;

const CategoryLabel = styled.span`
  position: absolute;
  bottom: 10px;
  left: 14px;
  color: white;
  font-weight: 600;
`;

interface HomeProps {
  children?: ReactNode;
}

const responsiveSetting = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 4,
      dots: true,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 3,
      dots: true,
    },
  },
];

const partners = [
  { url: "/static/images/providers_logos/seatgeek.png", name: "Seatgeek" },
  { url: "/static/images/providers_logos/stubhub.png", name: "StubHub" },
  {
    url: "/static/images/providers_logos/ticketliquidator.png",
    name: "Ticket Liquidator",
  },
  {
    url: "/static/images/providers_logos/ticketnetwork.png",
    name: "Ticket Network",
  },
  // { url: "/static/images/providers_logos/ticketmaster.png", name: "Ticket Master" },
  // { url: "/static/images/providers_logos/tickpick.png", name: "TicPick" },
  { url: "/static/images/providers_logos/viagogo.png", name: "Viagogo" },
  { url: "/static/images/providers_logos/vividseats.png", name: "Vivid Seats" },
];

// const SliderResponseSetting = [
//   {
//     breakpoint: 1024,
//     settings: {
//       slidesPerRow: 3,
//       rows: 1,
//     },
//   },
//   {
//     breakpoint: 480,
//     settings: {
//       slidesToShow: 1,
//       slidesToScroll: 1,
//       slidesPerRow: 2,
//       rows: 2,
//     },
//   },
// ];

const Home: FC<HomeProps> = () => {
  const theme = useTheme() as Theme;
  const sports = window.app_preload.sports as [];

  const activeSports = sports.filter((sport: any) => sport.isActive);
  // const inactiveSports = sports.filter((sport: any) => !sport.isActive);

  const categoryList = [
    { label: "Sports", imgSrc: "https://picsum.photos/800?random=1" },
    { label: "Theater", imgSrc: "https://picsum.photos/800?random=2" },
    { label: "Dance", imgSrc: "https://picsum.photos/800?random=3" },
    { label: "Music", imgSrc: "https://picsum.photos/800?random=4" },
    { label: "Sports", imgSrc: "https://picsum.photos/800?random=5" },
    { label: "Sports", imgSrc: "https://picsum.photos/800?random=6" },
    { label: "Sports", imgSrc: "https://picsum.photos/800?random=7" },
    { label: "Sports", imgSrc: "https://picsum.photos/800?random=8" },
    { label: "Sports", imgSrc: "https://picsum.photos/800?random=9" },
    { label: "Sports", imgSrc: "https://picsum.photos/800?random=10" },
    { label: "Sports", imgSrc: "https://picsum.photos/800?random=11" },
    { label: "Sports", imgSrc: "https://picsum.photos/800?random=12" },
  ];

  const sliderSettings: SliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    rows: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 4,
    initialSlide: 0,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesPerRow: 3,
          rows: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 2,
          rows: 2,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center justify-center px-4 pt-12 md:pt-20 lg:pt-28 mb-20">
        <EventComparisonHeader />
        <h2 className="text-lg text-center max-w-2xl mb-16">
          Compare event{" "}
          <span className="relative inline-block">
            ticket prices
            <div className="bg-[#DE6B2D] opacity-20 absolute top-2 w-full h-4 shadow-sm"></div>
          </span>{" "}
          from all the main online vendors, easily.
        </h2>
        <SearchBox />
      </div>
      {/* <Card sx={{ margin: "0 10px" }}>
        <CardHeader
          title={
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center" gap={1}>
                <GridIconWrapper>
                  <GridViewRounded sx={{ color: "white", fontSize: "18px" }} />
                </GridIconWrapper>

                <Typography
                  variant="body1"
                  color={theme.colors.alpha.black["100"]}
                  sx={{
                    fontSize: { md: 30, xs: 18 },
                    fontWeight: 700,
                  }}
                >
                  Categories
                </Typography>
              </Box>
              <Button
                sx={{
                  color: "black",
                  fontWeight: 500,
                  borderRadius: "999px",
                  border: "1px solid black",
                  padding: "4px 8px",
                }}
              >
                Browse All
              </Button>
            </Box>
          }
        ></CardHeader>
        <CardContent sx={{ width: "100%", paddingTop: 0 }}>
          <Slider {...sliderSettings}>
            {categoryList.map((item) => (
              <CategoryItem>
                <img width="100%" src={item.imgSrc} />
                <CategoryLabel>{item.label}</CategoryLabel>
              </CategoryItem>
            ))}
          </Slider>
        </CardContent>
      </Card> */}
      {/* <Typography
        variant="body1"
        color={theme.colors.alpha.black["100"]}
        sx={{
          fontSize: { md: 30, xs: 16 },
          fontWeight: 500,
          padding: "1rem 4rem 0.5rem",
          textAlign: "center",
        }}
      >
        Trusted by industry leaders and best-in-class brands
      </Typography> */}
      <div className="flex items-center justify-center w-full mb-32 flex-wrap">
        {partners.map((partner, index) => (
          <PartnerImage key={index} url={partner.url} name={partner.name} />
        ))}
      </div>
    </div>
  );
};
export default Home;

function PartnerImage({ url, name }: { url: string; name: string }) {
  return <img src={url} className="h-12 w-auto mx-4 md:mx-8 my-2" alt={name} />;
}
