import { FC } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Box,
  Container,
  Typography,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import { Theme, useTheme, styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import LogoSign from "../LogoSign";
import PageContentWrapper from "../../layouts/PageContentWrapper";
import { SocialIcon } from "react-social-icons";

const FooterWrapper = styled("div")(({ theme }) => ({
  textDecoration: "none",
  // marginTop: theme.spacing(4),
  padding: theme.spacing(4),
  backgroundColor: theme.colors.alpha.white[100],
  // color: theme.colors.alpha.trueWhite[100],
}));

const Item = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  paddingTop: theme.spacing(1),
  marginLeft: theme.spacing(4),
  // backgroundColor: theme.colors.alpha.black[100],
  // color: theme.colors.alpha.trueWhite[100],
}));

const SocialLink = styled(SocialIcon)(({ theme }) => ({
  width: "20px",
  height: "20px",
  color: theme.colors.alpha.trueWhite[100],
}));

const Footer: FC = () => {
  const theme: Theme = useTheme() as Theme;
  const SocialLinkStyle = {
    width: "30px",
    height: "30px",
  };
  return (
    <FooterWrapper className="footer-wrapper">
      <PageContentWrapper>
        <Grid
          container
          sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
        >
          <Grid
            item
            xs={12}
            sm={5}
            alignItems={"center"}
            display="flex"
            // direction={"column"}
            sx={{
              justifyContent: { xs: "center", md: "start" },
              alignItems: { xs: "start", md: "start" },
            }}
          >
            <Grid container direction={"column"}>
              <LogoSign />
              <Typography marginY={theme.spacing(2)}>
                {/* All trademarks, logos and brand names are the property of their respective owners. All company, product and service names used in this website are for identification purposes only. Use of these names, trademarks and brands does not imply endorsement. */}
                Compare the cheapest sports ticket prices instantly from the top
                ticket resellers. Shop from Stubhub, Ticketmaster, SeatGeek,
                Viagogo, and more
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={5} alignItems={"end"} alignContent={"end"}>
            <Stack
              direction={"row"}
              justifyContent="end"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Item to="/">HOME</Item>
              <Item to="/about">ABOUT</Item>
              <Item to="/contact">CONTACT</Item>
              <Item to="/blogs">BLOGS</Item>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="end"
              marginTop={theme.spacing(2)}
              sx={{ justifyContent: { xs: "center", md: "end" } }}
              spacing={4}
            >
              <SocialLink
                url="mailto:info@tixplorer.com"
                bgColor="white"
                style={SocialLinkStyle}
              />
              <SocialLink
                url="https://www.tiktok.com/@tixplorer_?_t=8Z9GcRI2OEt&_r=1"
                bgColor="white"
                network="tiktok"
                style={SocialLinkStyle}
              />
              <SocialLink
                url="https://instagram.com/tixplorer_?igshid=YWJhMjlhZTc="
                bgColor="white"
                network="instagram"
                style={SocialLinkStyle}
              />
            </Stack>
          </Grid>
        </Grid>
      </PageContentWrapper>
    </FooterWrapper>
  );
};
export default Footer;
