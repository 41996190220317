import React, { useContext, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material'
import firebaseApp from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { DialogContext } from '../../../contexts/DialogContext'
import axios from '../../../api/axios';

type Props = {
  onClose?: (status: boolean) => void;
}

function LoginDialog(props: Props) {

  const dialogs = useContext(DialogContext);
  const auth = getAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  async function handleSubmit() {
    try {
      const user = await signInWithEmailAndPassword(auth, email, password)
      const token = await user.user.getIdToken()
      // const response = await axios.post("/api/users/login", { email, password });
      localStorage.setItem("token", token);
    } catch (err) {
      console.error(err);
      // setError((err as any).response.data.error);
      setError("Invalid email or password");
      return;
    }
    closeDialog(true);
  }

  function goToForgotPassword() {
    dialogs.closeDialog();
    setTimeout(() => {
      dialogs.openDialog("forgotPassword");
    }, 100);
  }

  function closeDialog(status: boolean) {
    dialogs.closeDialog();
    props.onClose?.(status);
  }

  return (
    <Dialog open={dialogs.login} onClose={() => closeDialog(false)}>
      <DialogTitle>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Login
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 300, padding: '30px 15px' }}>
        <Grid container direction={'column'} spacing={'2px'} gap={'4px'}>
          {
            error && <Grid item>
              <Typography color="error" textAlign='center'>{error}</Typography>
            </Grid>
          }
          <Grid item>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ marginTop: '5px' }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              value={password}
              type='password'
              onChange={(e) => setPassword(e.target.value)}
              style={{ marginTop: '5px' }}
            />
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Button variant="contained" fullWidth onClick={handleSubmit}>Log In</Button>
          </Grid>
          <Grid item>
            <Button variant='text' onClick={goToForgotPassword}>Forgot your password?</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default LoginDialog