import {
  Box,
  Card,
  CardContent
} from '@mui/material';
import { useParams } from "react-router-dom";
import { Theme, useTheme } from '@mui/material/styles';
import PageTitle from '../../../../components/page/PageTitle';
import SuspenseLoader from '../../../../components/SuspenseLoader';
import APIFetchContainer, { VisualComponentProps } from '../../../../containers/APIFetchContainer';
import { API_NAMES } from '../../../../Enums';
import PageContentWrapper from '../../../../layouts/PageContentWrapper';
import ReactMarkdown from 'react-markdown'
import "./style.css"
import 'github-markdown-css/github-markdown.css';
const BlogContentPage = ({ blogDetails }: { blogDetails: any }) => {
  const theme = useTheme() as Theme;
  return <PageContentWrapper>
    <Box>
      <Card>
        <CardContent>
          <Box sx={{ height: { xs: '200px', md: '400px' } }}>
            <div style={{
              background: `url("/static/images/blogs/${blogDetails.image}")`,
              width: "100%",
              height: "100%",
              backgroundSize: 'cover',
              padding: theme.spacing(4),
              backgroundPosition: 'center',
            }} >
            </div>
          </Box>
          <Box sx={{paddingX: theme.spacing(10), marginTop: theme.spacing(10)}}>
            <PageTitle text={blogDetails.title} subtitle={blogDetails.date} />
            <Box sx={{ paddingY: theme.spacing(3) }} className='markdown-body'>
              <ReactMarkdown>
                {blogDetails.content}
              </ReactMarkdown>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  </PageContentWrapper>
}
const VisualComponent: React.FunctionComponent<VisualComponentProps> = ({ resultData }) => {
  if (resultData) {
    return <BlogContentPage blogDetails={resultData} />;
  } else {
    return <SuspenseLoader embedded={true} />
  }
};

const BlogsPage = () => {
  const { blogCode } = useParams();
  return <div>
    <APIFetchContainer apiName={API_NAMES.BLOG} needDataFetch={true} VisualComponent={VisualComponent} requestData={{ blogCode }} />
  </div>
}
export default BlogsPage;