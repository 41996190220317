import { FC } from "react";
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { Typography, Theme } from "@mui/material";
import { useTheme } from '@mui/material/styles';
interface MoreActionProb {
    text: string;
    action: () => void
}
const MoreAction: FC<MoreActionProb> = ({ text, action }) => {
    const theme = useTheme() as Theme;
    return <span onClick={action} style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }}>
        <ArrowDropDownCircleIcon fontSize={"small"} htmlColor={theme.colors.primary.light} />
        <Typography variant="body2" component="span" color={theme.colors.secondary.light}>
            {text}
        </Typography>
    </span>;
}

export default MoreAction;